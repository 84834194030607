<template>
  <div class="flex flex-col justify-center items-center font-proximaNormal">
    <HeaderGlobal
      titlemenu1="Contacts"
      :to1="'/contacts'"
      titlemenu2="Referrals"
    />

    <div class="w-11/12 mt-20 text-left">
      <div>
        <div class="flex flex-col justify-center items-center">
          <Pie :chartData="data" :options="options" />
        </div>
        <div class="mt-14 flex justify-start items-center">
          <div class="ml-1 flex flex-col justify-start items-start">
            <UserIcon />
          </div>
          <div class="ml-1 flex flex-col justify-start items-start">
            <h3 class="text-subheaderText">
              You have successfully referred
              <span class="font-proximaMedium text-headerText">{{
                referral.nb_referrals
              }}</span>
              friend(s).
            </h3>
          </div>
        </div>
        <div class="flex justify-start items-center">
          <div class="ml-1 flex flex-col justify-start items-start">
            <UserIcon iconColor="#F89520" />
          </div>
          <div class="ml-1 flex flex-col justify-start items-start">
            <h3 class="text-subheaderText">
              Only
              <span class="font-proximaMedium text-headerText">{{
                referral.nb_left_referrals
              }}</span>
              friend referrals to go, for a free subscription!
            </h3>
          </div>
        </div>
      </div>
      <div class="mt-8 mb-8">
        <h3 class="text-xl">Your personal referral link:</h3>
        <div
          class="mt-4 p-2 text-subheaderText border border-white rounded-md shadow-md flex items-center justify-between"
        >
          <span ref="textToCopy">{{ referral.referral_link_code }}</span>
          <SubmitButton
            :title="copyBtn"
            background="bg-primary"
            width="w-max"
            marginT="mt-0"
            paddingX="px-2"
            @click.native="copyReferralUrl"
          />
        </div>
      </div>
      <p class="text-lg">
        We rely on our community for marketing, and trust that happy users will
        refer their friends. <br /><br />
        For every regular paying user that you refer, we reduce your monthly
        subscription fee by 20%. If you have five friends with a paid account,
        <a href="#" class="text-primary">you can use our app for free!</a>
      </p>
      <p class="mt-6 mb-12">
        *The fee reduction is calculated on a monthly basis according to current
        users. So, if one of your friends unsubscribes, your fee will increase
        again accordingly.
      </p>
    </div>
  </div>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import SmallButton from "../buttons/SmallButton.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import UserIcon from "../icons/UserIcon.vue";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";
import { getUserReferals } from "../../services/commonService";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  components: {
    HeaderGlobal,
    SubmitButton,
    SmallButton,
    UserIcon,
    Pie,
  },
  data() {
    return {
      referral: {},
      inputs: {
        emails: [],
        invalid: false,
      },
      showModal: false,
      copyBtn: "Copy Link",
      data: {
        labels: ["Invited friend", "Friends left"],
        datasets: [
          {
            backgroundColor: ["#0AC0CB", "orange"],
            data: [0, 0],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
    };
  },

  async created() {
    const a = await getUserReferals();
    this.referral = a.data;
    this.data.datasets[0].data = [
      this.referral.nb_referrals,
      this.referral.nb_left_referrals,
    ];
  },

  methods: {
    copyReferralUrl() {
      const textToCopy = this.$refs.textToCopy;

      // Create a range and select the text
      const range = document.createRange();
      range.selectNode(textToCopy);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      // Use the Clipboard API to copy the text
      document.execCommand("copy");

      // Deselect the text (optional)
      window.getSelection().removeAllRanges();

      this.copyBtn = "Copied!";
    },
  },
};
</script>

<style lang="scss" scoped>
.file-input {
  display: none;
}
</style>
